import React, { FunctionComponent } from 'react'
import { Icon, IconType } from '../Icon'
import styles from './BadgeIcon.module.scss'

interface Props {
    icon: IconType
    size: [number, number]
    shift?: number
    badge: string | number
}

export const BadgeIcon: FunctionComponent<Props> = ({ icon, size, badge, shift = 1 }) => {
    const w = Math.round(size[0] / 4)
    const h = Math.round(size[1] / 4)

    return (
        <span className={styles.container} style={{ width: size[0] + w, height: size[1] + h }}>
            <Icon icon={icon} size={size} />
            <span
                className={styles.badge}
                style={{ fontSize: h * 2, left: w * (w - (w / 4) * shift), width: w * 2.5, height: h * 2.5 }}
            >
                {badge}
            </span>
        </span>
    )
}
