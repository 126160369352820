import React, { Fragment, FunctionComponent, useContext, useMemo, useRef, useState } from 'react'
import styles from './Profile.module.scss'
import classNames, { Argument } from 'classnames'
import { IUser, LocaleContext, useOutsideAlerter } from '@unicaiot/unica-iot-gallery-core'
import { TextButton } from '../Button/TextButton/TextButton'
import { Icon, Icons } from '../Icon/Icon'

import { ModalManager } from '../ModalManager/ModalManager'
import { ModalView } from '../ModalManager/Modals/ModalView'
import { LayoutType } from '../Types/Types'
import { DeviceLayout, RowLayout } from '../Layout/DeviceLayout/DeviceLayout'
import { Column } from '../Layout/Column/Column'
import { ResetPasswordWidget } from './ResetPasswordWidget/ResetPasswordWidget'
import { IconsWidget } from '../Info/IconsWidget/IconsWidget'

export interface Param {
    className?: Argument
    user: IUser
    onLogout?: () => void
}

export const Profile: FunctionComponent<Param> = ({ className, user, onLogout }) => {
    const [isVisiable, setIsVisiable] = useState(false)

    const locale = useContext(LocaleContext)
    const menuRef = useOutsideAlerter<HTMLDivElement, 'click'>('click', () => setIsVisiable(false))

    const profileViewRef = useRef<HTMLSpanElement>(null)
    const ProfileView = useMemo(() => {
        return (
            <ModalManager
                modalType={LayoutType.light}
                render={openModal => {
                    return <span onClick={openModal} ref={profileViewRef} />
                }}
                renderModal={closeModal => (
                    <ModalView requestClose={closeModal} modalType={LayoutType.light}>
                        <DeviceLayout>
                            <Fragment>
                                <RowLayout>
                                    <Column largeSpacing={true}>
                                        <ResetPasswordWidget />
                                    </Column>
                                    <Column largeSpacing={true} />
                                </RowLayout>
                            </Fragment>
                        </DeviceLayout>
                    </ModalView>
                )}
            />
        )
    }, [])

    const helpViewRef = useRef<HTMLSpanElement>(null)
    const HelpView = useMemo(() => {
        return (
            <ModalManager
                modalType={LayoutType.light}
                render={openModal => {
                    return <span onClick={openModal} ref={helpViewRef} />
                }}
                renderModal={closeModal => (
                    <ModalView requestClose={closeModal} modalType={LayoutType.light}>
                        <DeviceLayout>
                            <Fragment>
                                <RowLayout>
                                    <Column largeSpacing={true}>
                                        <IconsWidget />
                                    </Column>
                                    <Column largeSpacing={true} />
                                </RowLayout>
                            </Fragment>
                        </DeviceLayout>
                    </ModalView>
                )}
            />
        )
    }, [])

    return (
        <Fragment>
            {ProfileView}
            {HelpView}
            <div ref={menuRef} className={classNames(styles.userWrapper, className)}>
                <TextButton onClick={() => setIsVisiable(!isVisiable)}>
                    <Icon icon={Icons.profile} size={[24, 24]} />
                </TextButton>
                <div
                    className={classNames(styles.dropdownContent, {
                        [styles.isVisiable]: isVisiable,
                    })}
                >
                    <span
                        onClick={() => {
                            profileViewRef?.current?.click()
                        }}
                    >
                        {locale._('profile.profile')}
                    </span>
                    <span
                        onClick={() => {
                            helpViewRef?.current?.click()
                        }}
                    >
                        {locale._('profile.help')}
                    </span>
                    <span onClick={onLogout}> {locale._('profile.logout')}</span>
                </div>
                <span className={styles.name}>{`${user.given_name?.charAt(0).toUpperCase()}. ${
                    user.family_name
                }`}</span>
            </div>
        </Fragment>
    )
}
