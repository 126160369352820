import classNames from 'classnames'
import React, { FunctionComponent, ReactElement } from 'react'

import styles from './Pulse.module.scss'

export const Pulse: FunctionComponent<{ content: () => ReactElement; isFinit?: boolean }> = ({
    content,
    isFinit = true,
}) => {
    const modify = <T extends ReactElement>(child: T) => {
        const className = classNames(child.props.className, styles.pulse, {
            [styles.isFinit]: isFinit,
        })

        return React.cloneElement(child, {
            className,
        })
    }

    return <>{modify(content())}</>
}
