import { useEffect } from 'react'

export default function useScroll(scrollClassName: string = 'onScrollbar') {
    useEffect(() => {
        const handlers = new Map()

        function handleScroll(e: Event) {
            let classList: DOMTokenList | undefined = undefined

            if (e.target instanceof HTMLDocument) {
                classList = e.target.body.classList
            }

            if (e.target instanceof HTMLDivElement) {
                classList = e.target.classList
            }

            if (classList && classList.contains(scrollClassName) === false) {
                classList.add(scrollClassName)
            }

            if (handlers.has(e.target)) {
                clearTimeout(handlers.get(e.target))
                handlers.delete(e.target)
            }

            const handler = setTimeout(() => {
                classList?.remove(scrollClassName)
            }, 500)

            handlers.set(e.target, handler)
        }

        window.addEventListener('scroll', handleScroll, true)

        return () => window.removeEventListener('scroll', handleScroll)
    }, [scrollClassName])
}
