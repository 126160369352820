import React, { Fragment, FunctionComponent, useContext, useState } from 'react'
import { authService, LocaleContext, useUser } from '@unicaiot/unica-iot-gallery-core'
import styles from './ResetPasswordWidget.module.scss'
import { Widget } from '../../Widget/Widget'
import Title, { FontSize, HeadingLevel } from '../../Typography/Title'
import { Button, ButtonStyleType } from '../../Button/Button'
import { InfoText, TextSizeEnumType, TextStyleEnumType } from '../../Typography/Text/Text'

export const ResetPasswordWidget: FunctionComponent = () => {
    const user = useUser()
    const locale = useContext(LocaleContext)

    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState<string>('')

    const handleOnReset = async () => {
        if (!user?.email) {
            return
        }

        try {
            setLoading(true)
            setMessage('')

            const result = await authService.postResetPassword(user.email)

            if (result.status === 200) {
                setLoading(false)
                setMessage(locale._('resetPasswordWidget.successMessage'))
            } else {
                setMessage(result.statusText)
            }
        } catch (e) {
            setMessage(e.message)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Widget className={styles.widget} borders={true}>
            <Fragment>
                <Title className={styles.title} headingLevel={HeadingLevel.h1}>
                    {locale._('resetPasswordWidget.title')}
                </Title>
                <Title className={styles.subTitle} fontSize={FontSize.size14} headingLevel={HeadingLevel.h3}>
                    {locale._('resetPasswordWidget.subTitle')}
                </Title>
                <Button
                    buttonStyle={ButtonStyleType.primary}
                    isSubmit={false}
                    className={styles.submitButton}
                    loading={loading}
                    onClick={handleOnReset}
                    isDisabled={!user?.email}
                >
                    {locale._('resetPasswordWidget.reset')}
                </Button>
                <InfoText style={TextStyleEnumType.italic} size={TextSizeEnumType.medium} className={styles.message}>
                    {message}
                </InfoText>
            </Fragment>
        </Widget>
    )
}
