import styles from './Column.module.scss'

import React, { CSSProperties } from 'react'
import classNames, { Argument } from 'classnames'

interface Props {
    className?: Argument
    style?: CSSProperties
    largeSpacing?: boolean
    smallSpacing?: boolean
    isCentered?: boolean
    spaceBetween?: boolean
}

export const Column: React.FunctionComponent<Props> = ({
    style,
    children,
    largeSpacing,
    smallSpacing,
    className,
    isCentered,
    spaceBetween,
}) => {
    return (
        <div
            style={style}
            className={classNames(styles.container, className, {
                [styles.hasLargeSpacing]: largeSpacing,
                [styles.hasSmallSpacing]: smallSpacing,
                [styles.isCentered]: isCentered,
                [styles.hasSpaceBetween]: spaceBetween,
            })}
        >
            {children}
        </div>
    )
}
