import classNames, { Argument } from 'classnames'

import React, { Fragment, FunctionComponent } from 'react'
import { LayoutType } from '../Types/Types'
import styles from './Select.module.scss'

interface Props {
    selectRef?: React.RefObject<HTMLSelectElement>
    className?: Argument
    value?: string
    placeholder?: string
    disabled?: boolean
    onChange?: (value: string) => void
    options?: { value: string; name: string }[]
    keyPref?: string
    required?: boolean
    layoutType?: LayoutType
}

export const Select: FunctionComponent<Props> = ({
    selectRef,
    className,
    value = '',
    placeholder,
    disabled = false,
    onChange,
    options,
    keyPref,
    required = false,
    layoutType = LayoutType.light,
}) => {
    return (
        <select
            ref={selectRef}
            className={classNames(styles.selectWrapper, className, {
                [styles.light]: layoutType === LayoutType.light,
                [styles.dark]: layoutType === LayoutType.dark,
            })}
            placeholder={placeholder && ''}
            value={value}
            disabled={disabled}
            required={required}
            onChange={e => {
                onChange && onChange(e.target.value)
            }}
        >
            {
                <Fragment>
                    {placeholder && (
                        <option value={''} disabled={true}>
                            {placeholder}
                        </option>
                    )}
                    {options?.map((o, i) => {
                        return (
                            <option value={o.value} key={`${keyPref}_${i}`}>
                                {o.name}
                            </option>
                        )
                    })}
                </Fragment>
            }
        </select>
    )
}
