import classNames, { Argument } from 'classnames'

import React, { FunctionComponent } from 'react'
import styles from './TextButton.module.scss'
import { ButtonStyleType } from '../Button'
import { Icon, IconType } from '../../Icon/Icon'

interface Props {
    className?: Argument
    onClick?: () => void
    icon?: IconType
    isDisabled?: boolean
    buttonStyle?: ButtonStyleType
}

export const TextButton: FunctionComponent<Props> = ({
    className,
    children,
    onClick,
    icon,
    isDisabled,
    buttonStyle = ButtonStyleType.primary,
}) => {
    return (
        <button className={classNames(styles.wrapper, className)} onClick={onClick} disabled={isDisabled}>
            <span
                className={classNames(styles.text, {
                    [styles.isPrimary]: buttonStyle === ButtonStyleType.primary,
                    [styles.isSecondary]: buttonStyle === ButtonStyleType.secondary,
                    [styles.isTertiary]: buttonStyle === ButtonStyleType.tertiary,
                    [styles.isExternalLink]: buttonStyle === ButtonStyleType.externalLink,
                    [styles.isDisabled]: !!isDisabled,
                })}
            >
                {icon && <Icon icon={icon} className={styles.icon} />}
                {children}
            </span>
        </button>
    )
}
