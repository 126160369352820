import classNames, { Argument } from 'classnames'

import React, { Fragment, FunctionComponent } from 'react'
import { Column } from '../Column/Column'
import { MediaQuery, Screensize } from '../MediaQuery/MediaQuery'
import { Row } from '../Row/Row'
import styles from './DeviceLayout.module.scss'

export const DeviceLayout: FunctionComponent = ({ children }) => {
    return (
        <Column className={styles.main} largeSpacing={true}>
            {children}
        </Column>
    )
}

export const RowLayout: FunctionComponent<{ className?: Argument }> = ({ className, children }) => {
    return (
        <Fragment>
            <MediaQuery breakpoint={Screensize.mobile}>
                <Column className={classNames(className)} largeSpacing={true}>
                    {children}
                </Column>
            </MediaQuery>
            <MediaQuery breakpoint={Screensize.tablet}>
                <Row className={classNames(styles.row, className)} largeSpacing={true}>
                    {children}
                </Row>
            </MediaQuery>
        </Fragment>
    )
}
