import classNames, { Argument } from 'classnames'

import React, { FunctionComponent } from 'react'
import { LayoutType } from '../Types/Types'
import styles from './Widget.module.scss'

interface Props {
    className?: Argument
    layout?: LayoutType
    borders?: boolean
}

export const Widget: FunctionComponent<Props> = ({
    className,
    children,
    layout = LayoutType.light,
    borders = false,
}) => {
    return (
        <div
            className={classNames(styles.container, className, {
                [styles.light]: layout === LayoutType.light,
                [styles.dark]: layout === LayoutType.dark,
                [styles.borders]: borders,
            })}
        >
            {children}
        </div>
    )
}
