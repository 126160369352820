import { routes, useUser } from '@unicaiot/unica-iot-gallery-core'
import React, { Fragment, FunctionComponent, useEffect } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { Login } from '../components/Login/Login'

export const LoginView: FunctionComponent<
    RouteComponentProps<Record<string, never>, Record<string, unknown>, { force: boolean }>
> = params => {
    return params.location.state?.force ? <Login /> : <LoginRedirect />
}

const LoginRedirect: FunctionComponent = () => {
    const user = useUser()
    const history = useHistory()

    useEffect(() => {
        if (user) {
            history.push(routes.index)
        }
    }, [user, history])

    return user === null ? <Login /> : <Fragment />
}
