import { ActivityIndicator, ActivityIndicatorEnumType } from '@unicaiot/unica-iot-gallery-core'
import React, { Fragment, FunctionComponent, ReactNode, useState } from 'react'
import styles from './TreeView.module.scss'

export interface TreeNode {
    node: ReactNode
    onClick?: () => void
    clicked?: boolean
    loading: boolean
    active: boolean
    color?: string
    children: TreeNode[] | null
}

export interface TreeViewProps {
    root: ReactNode
    nodes: TreeNode[]
}

export const TreeView: FunctionComponent<TreeViewProps> = ({ root, nodes }) => {
    const [data, setData] = useState<TreeNode[]>(nodes)

    const disactivateAll = (node: TreeNode) => {
        node.active = false
        node.children?.forEach(disactivateAll)
    }

    const onClickInternal = (node: TreeNode) => {
        if (node.active) {
            disactivateAll(node)
        } else {
            node.active = true
        }

        setData(Object.assign([], data))
    }

    const isExpandable = (node: TreeNode) => {
        return node.loading || node.children
    }

    const buildTree = (nodes: TreeNode[] | null, parant?: TreeNode) => {
        return (
            <ul className={!parant || parant.active ? styles.visiable : styles.nonVisiable}>
                {parant?.loading ? (
                    <ActivityIndicator size={ActivityIndicatorEnumType.medium} className={styles.loader} />
                ) : (
                    nodes?.map((node, i) => {
                        return (
                            /* eslint-disable */
                                <li
                                    key={i}
                                    onClick={e => {
                                        e.stopPropagation()
                                        isExpandable(node) && onClickInternal(node)
                                        node.onClick && (node.clicked = true) && node.onClick()
                                    }}
                                /* eslint-enable */
                            >
                                <div
                                    className={`${styles.node}${node.active ? ` ${styles.active}` : ''}${
                                        isExpandable(node) ? ` ${styles.expandable}` : ''
                                    }${isExpandable(node) || node.onClick ? ` ${styles.clickable}` : ''}${
                                        node.clicked ? ` ${styles.clicked}` : ''
                                    }`}
                                    style={{ backgroundColor: node.color }}
                                >
                                    {node.node}
                                </div>
                                {buildTree(node.children, node)}
                            </li>
                        )
                    })
                )}
            </ul>
        )
    }

    return (
        <Fragment>
            <div>{root}</div>
            <div className={styles.tree}>{buildTree(nodes)}</div>
        </Fragment>
    )
}
