import classNames, { Argument } from 'classnames'
import React, { FunctionComponent, useEffect, useRef } from 'react'
import styles from './QuestionMark.module.scss'
import ReactTooltip, { Place } from 'react-tooltip'
import { Icon, Icons } from '../Icon/Icon'

interface Props {
    id?: string
    className?: Argument
    place?: Place
    delayHide?: number
}

export const QuestionMark: FunctionComponent<Props> = ({
    id = 'qm',
    className,
    children,
    place = 'top',
    delayHide,
}) => {
    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
        window.addEventListener('touchmove', touchMove)
    }, [])

    const touchMove = () => {
        if (ref.current) {
            ReactTooltip.hide(ref.current)
        }
    }

    return (
        <>
            <div className={classNames(styles.icon, className)} ref={ref} data-tip={true} data-for={id}>
                <Icon icon={Icons.question} size={[24, 24]} />
            </div>
            <ReactTooltip
                id={id}
                className={styles.tooltip}
                delayHide={delayHide}
                effect={'solid'}
                place={place}
                border={true}
                borderColor={'#10264d'}
                arrowColor={'#10264d'}
                backgroundColor={'#fff'}
            >
                {children}
            </ReactTooltip>
        </>
    )
}
