import classNames, { Argument } from 'classnames'

import React, { FunctionComponent } from 'react'
import { TextButton } from '../../Button/TextButton/TextButton'
import { Icons } from '../../Icon/Icon'
import { LayoutType } from '../../Types/Types'
import styles from './ModalView.module.scss'

interface Props {
    className?: Argument
    requestClose?: () => void
    modalType?: LayoutType
}

export const ModalView: FunctionComponent<Props> = ({
    className,
    requestClose,
    children,
    modalType = LayoutType.light,
}) => {
    return (
        <div
            className={classNames(styles.wrapper, className, {
                [styles.light]: modalType === LayoutType.light,
                [styles.dark]: modalType === LayoutType.dark,
            })}
        >
            <div className={styles.closeIcon}>
                <TextButton onClick={requestClose} icon={Icons.close} />
            </div>
            <div className={styles.contentWrapper}>{children}</div>
        </div>
    )
}
