import React, { FunctionComponent } from 'react'
import styles from './Header.module.scss'
import classNames, { Argument } from 'classnames'
import { config, IUser } from '@unicaiot/unica-iot-gallery-core'

import { Link } from 'react-router-dom'
import { Profile } from '../Profile/Profile'
import { BuildingInsightLogo } from '../Logo/BuildingInsightLogo'

export interface HeaderParam {
    className?: Argument
    user?: IUser
    onLogout?: () => void
}

export const Header: FunctionComponent<HeaderParam> = ({ className, user, onLogout }) => {
    return (
        <div className={classNames(styles.authWrapper, className)}>
            <Link to={config.defaultPath}>
                <BuildingInsightLogo className={styles.logo} />
            </Link>
            {user && <Profile user={user} onLogout={onLogout} />}
        </div>
    )
}
