import React, { Fragment, FunctionComponent, useContext } from 'react'
import { LocaleContext } from '@unicaiot/unica-iot-gallery-core'
import styles from './IconsWidget.module.scss'
import { Widget } from '../../Widget/Widget'
import Title, { FontSize, HeadingLevel } from '../../Typography/Title'
import { Row } from '../../Layout/Row/Row'
import { Icon, Icons } from '../../Icon/Icon'
import { InfoText } from '../../Typography/Text/Text'
import { Pulse } from '../../Pulse/Pulse'
import { BadgeIcon } from '../../Icon/BadgeIcon/BadgeIcon'

export const IconsWidget: FunctionComponent = () => {
    const locale = useContext(LocaleContext)

    return (
        <Widget className={styles.widget} borders={true}>
            <Fragment>
                <Title className={styles.title} headingLevel={HeadingLevel.h1}>
                    {locale._('iconsWidget.title')}
                </Title>
                <Title className={styles.subTitle} fontSize={FontSize.size14} headingLevel={HeadingLevel.h3}>
                    {locale._('iconsWidget.subTitle')}
                </Title>
                <div className={styles.icons}>
                    <Row>
                        <Icon icon={Icons.profile} size={[24, 24]} />
                        <InfoText>{locale._('iconsWidget.profile')}</InfoText>
                    </Row>
                    <Row>
                        <Icon icon={Icons.full} size={[24, 24]} />
                        <InfoText>{locale._('iconsWidget.full')}</InfoText>
                    </Row>
                    <Row>
                        <Icon icon={Icons.export} size={[24, 24]} />
                        <InfoText>{locale._('iconsWidget.export')}</InfoText>
                    </Row>
                    <Row>
                        <Pulse isFinit={false} content={() => <span className={styles.pulse} />} />
                        <InfoText>{locale._('iconsWidget.pulse')}</InfoText>
                    </Row>
                    <Row>
                        <Icon icon={Icons.close} size={[24, 24]} />
                        <InfoText>{locale._('iconsWidget.close')}</InfoText>
                    </Row>
                    <Row>
                        <Icon icon={Icons.reset} size={[24, 24]} />
                        <InfoText>{locale._('iconsWidget.reset')}</InfoText>
                    </Row>
                    <Row>
                        <BadgeIcon icon={Icons.excel} size={[20, 20]} badge={0} />
                        <InfoText>{locale._('iconsWidget.excel')}</InfoText>
                    </Row>
                    <Row>
                        <BadgeIcon icon={Icons['compare-add']} size={[20, 20]} shift={2} badge={'+'} />
                        <InfoText>{locale._('iconsWidget.compareAdd')}</InfoText>
                    </Row>
                    <Row>
                        <BadgeIcon icon={Icons['compare-add']} size={[20, 20]} shift={2} badge={'-'} />
                        <InfoText>{locale._('iconsWidget.compareRemove')}</InfoText>
                    </Row>
                </div>
            </Fragment>
        </Widget>
    )
}
