import { useEffect, useRef } from 'react'

export default function useOutsideAlerter<T extends Node, P extends keyof DocumentEventMap>(
    type: P,
    callback: () => void
) {
    const ref = useRef<T>(null)

    useEffect(() => {
        function handleClickOutside(event: DocumentEventMap[P]) {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                callback()
            }
        }

        // Bind the event listener
        document.addEventListener(type, handleClickOutside, true)
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener(type, handleClickOutside, true)
        }
    })

    return ref
}
