import React, { Fragment, FunctionComponent, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useUser, authService, routes, config } from '@unicaiot/unica-iot-gallery-core'
import { Header } from '../../../Core/components/Header/Header'

export interface AuthParam {
    className?: string
}

export const Auth: FunctionComponent<AuthParam> = ({ className, children }) => {
    const user = useUser()

    const history = useHistory()

    useEffect(() => {
        if (user === null) {
            history.push(routes.login, { force: true })
        }
    }, [user, history])

    const handleOnLogout = async () => {
        try {
            await authService.getLogout()
        } catch {}

        history.push(config.defaultPath)
        history.push(routes.login, { force: true })
    }

    return user ? (
        <Fragment>
            <Header className={className} onLogout={handleOnLogout} user={user} />
            {children}
        </Fragment>
    ) : null
}
