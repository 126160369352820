import { authService } from './service'
import { IUser } from './types'
import { useEffect, useState } from 'react'

export const useUser = (): IUser | null | undefined => {
    const [user, setUser] = useState<IUser | null | undefined>(undefined)

    useEffect(() => {
        ;(async function () {
            setUser(await authService.User())
        })()
    }, [])

    return user
}
